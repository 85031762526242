import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';


function PrivateRoutes() {
    const logok = sessionStorage.getItem("log");


    return logok ? <Outlet /> : <Navigate to="/login" />;
  }

export default PrivateRoutes;
