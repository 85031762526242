import React, { useEffect,useState } from "react"
import api from "../api/api";
import { useNavigate } from "react-router-dom"


const Login = () => {
const navigate = useNavigate();
const [password,setPassword] = useState("");
const [utente,setUtente] = useState("");

useEffect(()=>{
    document.body.style.backgroundColor = "#efefef"
},[])




const handleLogin = async(e) => {
e.preventDefault();
const response = await api.post("login", {
    email: utente,
    password: password,
  });
console.log(response.data.messaggio)


if(response.data.messaggio ==="NON ok"){
    alert("User e password errati")
}else{
    sessionStorage.setItem("JWT",response.data.messaggio)
    navigate("/Home");

    sessionStorage.setItem("log",true)
}


sessionStorage.setItem("log",true)
//sessionStorage.setItem("proveJWT",user.data.utente.token)

}




return(

<section className="bg-gray-50 dark:bg-gray-900 h-screen">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" className="flex items-wcenter mb-6 text-2xl text-center font-semibold text-gray-900 dark:text-white">
      <img
          alt="foto"
            src={require("../assets/logo.png")}
            className="mr-2 "
          />
          Miller Notarize  
      </a>
      <div className="w-full bg-millerBlue shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl dark:text-white">
                 Effettua l'accesso
              </h1>
              <form className="space-y-4 md:space-y-6" action="#" onSubmit={handleLogin}>
                  <div>
                      <label for="email" className="block mb-2 text-sm font-medium text-white dark:text-white">Utente</label>
                      <input value={utente} onChange={(e) => setUtente(e.target.value)} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm  focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" required="" />
                  </div>
                  <div>
                      <label for="password" className="block mb-2 text-sm font-medium text-white dark:text-white">Password</label>
                      <input value={password} onChange={(e) => setPassword(e.target.value)}  type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm  focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                  </div>
                
                  <button type="submit" className="w-full text-white bg-[#1976d2] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium  text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" onClick={handleLogin}>Log in</button>
                
              </form>
          </div>
      </div>
  </div>
</section>

) 

}

export default Login