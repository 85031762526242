import { DataGridPro, GridToolbar, itIT,  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  useGridApiRef,
  gridVisibleRowCountSelector,
  gridVisibleColumnDefinitionsSelector,
  gridVisibleSortedRowEntriesSelector, } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from "react";
import api from '../api/api';
import moment from "moment";
import { Search } from '@mui/icons-material';
import {BarChart,Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';




const Analytics = () =>{
const [From,setFrom] = useState(moment(new Date()).subtract(7, 'months').format("YYYY-MM-DD"));
const [To,setTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
const [sellingPrevision,setSellingPrevision] = useState([]);
const [loading,setLoading] = useState(false);
const [totale,setTotale] = useState(0);
const [variabileMesi,setVariabileMesi] = useState(12);
const [totaleFiltrato, setTotaleFiltrato] = useState([]);
const [articoliSelezionati,setArticoliSelezionati] = useState([]);
const [dataItemsQTY,setDataItemsQTY] = useState([]);
const [dataItemsAM,setDataItemsAM] = useState([]);
const [etichetteQTY,setEtichetteQTY] = useState([])
const [etichetteAM,setEtichetteAM] = useState([])
const [days,setDays] = useState(0);
const apiRef = useGridApiRef();

  const columns = [
    { field: 'Item', headerName: 'Articolo', width: 150 },
    {
      field: 'Description',
      headerName: 'Descrizione',
      width: 300,
    },
    {
      field: 'Department',
      headerName: 'Reparto',
      width: 150,
    },
    {
      field: 'HomogeneousCtg',
      headerName: 'Categoria Omogenea',
      width: 110,
    },
    {
      field: 'Disp',
      headerName: 'Disponibilità',
      width: 110,
    },
    {
      field: 'InitialBookInv',
      headerName: 'Qtà giacenza iniziale A.C.',
      width: 110,
    },
    {
      field: 'InitialBookInvValue',
      headerName: 'Valore giacenza iniziale A.C',
      renderCell:(params) => <div>{params.row.InitialBookInvValue?(Math.round(params.row.InitialBookInvValue* 100) / 100) + " €":0+ " €"}</div>,
  
      width: 110,
    },
    {
      field: 'PurchasesQty',
      headerName: 'Qtà Acquisti',
      width: 110,
    },
    {
      field: 'PurchasesValue',
      headerName: 'Valore Acquisti',
      width: 110,
    },
    {
      field: 'SalesQty',
      headerName: 'Qtà vendita stato attuale',
      width: 110,
      type:"number",
      renderCell:(params) => params.row.SalesQty >0?params.row.SalesQty:0,
    },
 {
      field: 'SalesValue',
      headerName: 'Valore venduta stato attuale',
      renderCell:(params) => <div>{params.row.SalesValue?(Math.round(params.row.SalesValue* 100) / 100) + " €":0+ " €"}</div>,
  
      width: 110,
    },
    {
      field: 'RimInMesi',
      headerName: 'Rim. in mesi',
      renderCell:(params) => <div>{params.row.SalesQty && params.row.Disp> 0?(Math.round((((params.row.SalesQty/days) * 30)/params.row.Disp)*100 ) /100) :0}</div>,
  
      width: 110,
    },
    {
      field: 'LastCost',
      headerName: 'Prezzo acquisto',
      renderCell:(params) => <div>{params.row.LastCost?(Math.round(params.row.LastCost* 100) / 100) + " €":0+ " €"}</div>,
  
      width: 110,
    },
    {
      field: 'PzzoVendita',
      headerName: 'Prezzo vendita',
      renderCell:(params) => <div>{params.row.PzzoVendita?(Math.round(params.row.PzzoVendita* 100) / 100) + " €":0+ " €"}</div>,
  
      width: 110,
    },

    {
      field: 'PzzoVenditaIVAEsclusa',
      headerName: 'Prezzo vendita senza iva',
      renderCell:(params) => <div>{params.row.PzzoVenditaIVAEsclusa?(Math.round(params.row.PzzoVenditaIVAEsclusa* 100) / 100) + " €":0+ " €"}</div>,
  
      width: 110,
    },
    {
      field: 'Ricarico',
      headerName: 'Ricarico',
      renderCell:(params) => <div>{params.row.PzzoVenditaIVAEsclusa && params.row.LastCost?(Math.round(((params.row.PzzoVenditaIVAEsclusa - params.row.LastCost))* 100) / 100) + " €":0+ " €"}</div>,
  
      width: 110,
    },
    {
      field: 'PrezzoMedioVendita',
      headerName: 'Prezzo medio vendita',
      renderCell:(params) => <div>{params.row.SalesValue && params.row.SalesQty?(Math.round(((params.row.SalesValue/params.row.SalesQty))* 100) / 100) + " €":0+ " €"}</div>,
  
      width: 110,
    },
    {
      field: 'VenditaGiornaliera',
      headerName: 'Vendita Giornaliera',
      renderCell:(params) => <div>{params.row.SalesQty?(Math.round(params.row.SalesQty/days* 100) / 100):0}</div>,
  
      width: 110,
    },
    {
      field: 'VenditaMensile',
      headerName: 'Vendita Mensile',
      renderCell:(params) => <div>{params.row.SalesQty?Math.round(((params.row.SalesQty/days)* 30)*100 ) /100:0}</div>,
  
      width: 110,
    },
    {
      field: 'MerceDaRiordinare',
      headerName: 'Merce da riordinare',
      renderCell:(params) => <div>{params.row.SalesQty && params.row.Disp> 0?
      <div style={{color:params.row.Disp - (Math.round((((params.row.SalesQty/days)* 30)*variabileMesi )*100 ) /100) >=0?"green":"red" }}>
       {Math.round((params.row.Disp - (Math.round((((params.row.SalesQty/days)* 30)*variabileMesi )*100 ) /100)) *100)/100 }
       </div> :
       0}</div>,
  
      width: 110,
    },

    /*
    {
      field: 'AvgQty',
      headerName: 'Qtà media',
      width: 110,
    },
    {
      field: 'MaxQty',
      headerName: 'Qtà max snigolo ordine',

      width: 110,
    },
    {
      field: 'MinQty',
      headerName: 'Qtà max sinigolo ordine',
      type: 'number',
      width: 110,
    },
    {
      field: 'ValVendita',
      headerName: 'Valore Vendita stato attuale Periodo',
       // la somma di questo genere il tutto
      width: 110,
    },*/
    
  ];
  useEffect(() => {
    apiRef.current.subscribeEvent("stateChange", () => {
      const count = gridVisibleSortedRowEntriesSelector(apiRef.current.state);

      let arr = count.map(r => r.model.SalesValue);
      arr = arr.filter(a => a );

      let tot = arr.reduce((a, b) => a + b, 0)

      setTotaleFiltrato(tot);
    });
    setDays(moment(To).diff(moment(From),'days'))
  }, [apiRef]);
  
  const loadSellingPrevisionFilter = async(type) => {// in  base al tasto schiacciato filtro
    setLoading(true);
    setDataItemsQTY([]);
    setDataItemsAM([]);
     setEtichetteQTY([]);
     setEtichetteAM([])
    let to = moment(new Date()).format("YYYY-MM-DD");
    let from = "";
    switch (type){
      case "3":
        from = moment(new Date()).subtract(3, 'months').format("YYYY-MM-DD");
        break;
      case "6":
        from = moment(new Date()).subtract(6, 'months').format("YYYY-MM-DD");
        break;
        case "12":
          from = moment().startOf('year').format("YYYY-MM-DD");
          break;

    }
    setFrom(from);
    setTo(to)
    await api.get('/analysis', {params:{da:from,a:to},headers: {'Authorization': `Bearer ${sessionStorage.getItem("proveJWT")}`
        }})
        .then( res=> {
          setSellingPrevision(res.data.data.recordset)
          let arr = res.data.data.recordset.map(r => r.SalesValue);
          arr = arr.filter(a => a );

          let tot = arr.reduce((a, b) => a + b, 0)
          tot = ( Math.round(tot* 100) / 100) 
          let notDecimal = 
          console.log(typeof(tot))
          setTotale(tot);

        })
        setLoading(false);
}

  const loadSellingPrevision = async() => {
    setLoading(true);
    await api.get('/analysis', {params:{da:From,a:To},headers: {'Authorization': `Bearer ${sessionStorage.getItem("proveJWT")}`
        }})
        .then( res=> {
         
          setSellingPrevision(res.data.data.recordset);

          let arr = res.data.data.recordset.map(r => r.SalesValue);
          arr = arr.filter(a => a );

          let tot = arr.reduce((a, b) => a + b, 0)

          setTotale(tot);
          setArticoliSelezionati([]);


        })
        setDays(moment(To).diff(moment(From),'days'))
        setLoading(false);
        setDataItemsQTY([]);
        setDataItemsAM([]);
         setEtichetteQTY([]);
         setEtichetteAM([])
      
}


const loadSellingItems = async(items) => {

  if(items){
  await api.get('/analysis', {params:{da:From,a:To,items:items},headers: {'Authorization': `Bearer ${sessionStorage.getItem("proveJWT")}`
}})
.then( res=> {
 setDataItemsQTY(res.data.AnalyticsDataQTY);
 setDataItemsAM(res.data.AnalyticsDataAM);
  setEtichetteQTY(res.data.etichetteQTY);
  setEtichetteAM(res.data.etichetteAM)
})
  }



}

const CustomTooltipEuro = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log(payload,label)
    return (
      <div className="custom-tooltip">
        <p className="label text-white font-bold">{`${label}`}</p>
        <div>
          {payload.map((pld) => (
            <div style={{ display: "inline-block", padding: 10 }}>
              <div >{pld.dataKey}: <span style={{ color: pld.fill }}><b> {pld.value} €</b></span></div>
              
            </div>
          ))}
        </div>
      </div>
    );
  }
};



const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log(payload,label)
    return (
      <div className="custom-tooltip">
        <p className="label text-white font-bold" >{`${label}`}</p>
        <div>
          {payload.map((pld) => (
            <div style={{ display: "inline-block", padding: 10 }}>
              <div >{pld.dataKey}: <span style={{ color: pld.fill }}><b> {pld.value}</b></span></div>
              
            </div>
          ))}
        </div>
      </div>
    );
  }
};

useEffect(() => {

  loadSellingPrevision()

},[])









    return (       
     <div className='flex flex-col space-y-2'>
<div className=" overflow-hidden shadow-lg bg-white w-full  p-4">
  <div className="flex flex-row w-full space-x-4">
    <div className="relative max-w-sm">
    <div class="relative flex  w-full flex-row-reverse overflow-clip">
    <input value={From} onChange={(e) => setFrom(e.target.value)} type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  block w-full  p-2.5 " placeholder="Da" />
    <label class="flex items-center border border-orangeCrea bg-orangeCrea px-2 text-sm text-white transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-white" for="domain">Da</label>
  </div>
      
    </div>
    <div className="relative max-w-sm">
    <div class="relative flex  w-full flex-row-reverse overflow-clip">
      <input value={To} onChange={(e) => setTo(e.target.value)} type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="A" />
      <label class="flex items-center border border-orangeCrea bg-orangeCrea px-2 text-sm text-white transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-white" for="domain">A</label>
  </div>
    </div>
    <div className="relative">
  
    <button type="submit" className="hover:bg-orangeCreaHover text-white bg-orangeCrea hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium  px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" onClick={loadSellingPrevision}>Filtra</button>
    </div>
    <div className="relative ">
    <button type="submit" className="hover:bg-orangeCreaHover text-white bg-orangeCrea hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium  px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" onClick={() => loadSellingPrevisionFilter("3")}>3 Mesi</button>
    </div>
    <div className="relative ">
    <button type="submit" className="hover:bg-orangeCreaHover text-white bg-orangeCrea hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium  px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" onClick={() => loadSellingPrevisionFilter("6")}>6 Mesi</button>
    </div>
    <div className="relative ">
    <button type="submit" className="hover:bg-orangeCreaHover text-white bg-orangeCrea hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium  px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" onClick={() => loadSellingPrevisionFilter("12")}>Inizio anno</button>
    </div>
    <div className="relative max-w-sm">
    <div class="relative flex  w-full flex-row-reverse overflow-clip">
      <input value={variabileMesi} onChange={(e) => setVariabileMesi(e.target.value)} type="numeric" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Variabile Mesi" />
      <label class="flex items-center border border-orangeCrea bg-orangeCrea px-2 text-sm text-white transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-white" for="domain">Mesi</label>
  </div>
    </div>
    
  </div>
  
 


</div>




<div className=" overflow-hidden shadow-lg bg-white w-full  p-4">
  
<div className="flex flex-col w-full space-x-4  h-[600px]">
    <div className="h-full">
      <DataGridPro apiRef={apiRef}  loading={loading} columns={columns} rows={sellingPrevision} getRowId={(el) => el.Item}  localeText={itIT.components.MuiDataGrid.defaultProps.localeText} components={{Toolbar: GridToolbar}}
       onSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = sellingPrevision.filter((row) =>
          selectedIDs.has(row.Item)
        );
        let items = selectedRowData.map(art => art.Item).join();
        loadSellingItems(selectedRowData.map(art => art.Item));
        /*setVisiteSelezionate(selectedRowData);
        let dataAPI = selectedRowData.map((v) => {
          return {
            IDMansione: v.IDMansione,
            Data: v.Data,
            IDCliente: v.IDCliente,
            ID: v.ID,
          };
        });
        setVisiteSelezionateAPI(dataAPI); */
      }}
      
      />
    </div>
  </div>
  </div>

  <div className='flex flex-row space-x-2'>

  <div className="flex overflow-hidden shadow-lg bg-white w-full  p-4 ">
  Fatturato filtro {moment(From).format("DD/MM/YYYY") +" - " +moment(To).format("DD/MM/YYYY")}:  <br /> {commafy(Math.round(totaleFiltrato*100)/100) +" €"}
  </div>
  <div className="flex overflow-hidden shadow-lg bg-white w-full  p-4 ">
  Fatturato  {moment(From).format("DD/MM/YYYY") +" - " +moment(To).format("DD/MM/YYYY")}:  <br /> {commafy(Math.round(totale*100)/100) +" €"}
  </div>
  </div>
{dataItemsQTY.length>0?
  <div className='flex flex-row space-x-2'>
  <div className="flex flex-col overflow-hidden shadow-lg bg-white w-full h-64  p-4 ">
<div className='flex '>Report Quantità</div>

  <ResponsiveContainer width="100%" height="100%">
     
   
      <BarChart 
          width={500}
          height={300}
          data={dataItemsQTY}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
         <YAxis />
          <Tooltip content={<CustomTooltip />}/>
          <Legend />
          {etichetteQTY.map((e,index) => 
          
          <Bar  key={index} type="monotone" dataKey={e.name} fill={e.color}/>
          )
          
          }
         

        </BarChart >
        </ResponsiveContainer>
    </div>
    
  </div> :""}



  {dataItemsAM.length>0?
  <div className='flex flex-row space-x-2'>
  <div className="flex flex-col overflow-y shadow-lg bg-white w-full h-64  p-4 ">
<div className='flex '>Report Fatturato</div>

  <ResponsiveContainer width="100%" height="100%">
     
   
      <BarChart 
          width={500}
          height={300}
          data={dataItemsAM}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
         <YAxis />
          <Tooltip content={<CustomTooltipEuro />}/>
          <Legend />
          {etichetteAM.map((e,index) => 
          
          <Bar  key={index} type="monotone" dataKey={e.name} fill={e.color}/>
          )
          
          }
         

        </BarChart >
        </ResponsiveContainer>
    </div>
    
  </div>:""}

  </div>
  
    )
}

export default Analytics



/**
 
<div class="relative max-w-sm">
  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
  </div>
  <input  type="date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Da" />
</div>


   <div style={{color:Math.round(Math.round(((params.row.SalesQty/days)* 30))* (variabileMesi - (Math.round(((params.row.SalesQty/days * 30)/params.row.Disp)*100 ) /100))*100)/100 >=0?"green":"red" }}>
       {Math.round(Math.round(((params.row.SalesQty/days)* 30))* (variabileMesi - (Math.round(((params.row.SalesQty/days * 30)/params.row.Disp)*100 ) /100))*100)/100 }
       </div>
 */


function commafy( num ) {

  var str = num.toString().split('.');
  if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  
  return str.join('.');
}