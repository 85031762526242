import {  DashboardSharp, EqualizerSharp, KeyboardArrowDownSharp, KeyboardArrowUpSharp, SellSharp } from "@mui/icons-material";
import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const Menus = [
    { Title: "Home", icon: <DashboardSharp />,multiLevel:false }, //mi servere multiLevel per capire se è èun menù con sotto menu
    //{ title: "Accounts", src: "User", gap: true }, //gap true crea spazio in quello sopra

   , 
  ];

  useEffect(() => {
    document.body.style.backgroundColor = "#efefef"
  }, [])

  return (
    <>
      <div className="h-screen flex">
        <div
          className={` ${open ? "w-72" : "w-20 "
            } bg-millerBlue h-screen p-5  pt-8 relative duration-300 `}
        >
          <img
            alt="foto"
            src={require("../assets/control.png")}
            className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
           border-2 rounded-full  ${!open && "rotate-180"}`}
            onClick={() => setOpen(!open)}
          />
          <div className="flex gap-x-4 items-center">
            <img
              alt="foto"
              src={require("../assets/LogoC.png")}
              className={`cursor-pointer duration-700 ${open && "rotate-[360deg]"
                }`}
            />
            <h1
              className={`text-white origin-left font-medium text-xl duration-200 ${!open && "scale-0"
                }`}
            >
              Miller Notarize
            </h1>
          </div>
          <ul className="pt-6">
            {Menus.map((Menu, index) => (
              <li
                // onClick={()=> navigate("/"+Menu.title) }
                key={index}

              >
                <button type="button" onClick={Menu.multiLevel?() => document.getElementById("dropdown-"+Menu.Title)?.classList.contains('hidden') ? document.getElementById("dropdown-"+Menu.Title).classList.remove("hidden") : document.getElementById("dropdown-"+Menu.Title).classList.add("hidden") : ()=> navigate("/"+Menu.Title)} className={`flex w-full  p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${(window.location.href.indexOf("/" + Menu.Title) !== -1) && "bg-light-white text-orangeCrea"
                  } `}
                  aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                 {Menu.icon} <span className={ `flex-1 ml-3 text-left whitespace-nowrap ${!open && "hidden"} origin-left duration-200`} sidebar-toggle-item>{Menu.Title}

                  {
                  Menu.multiLevel?<KeyboardArrowDownSharp className={ ` ${!open && "hidden"} origin-left duration-200`}/>:""
                  
                  }
                  </span>
                </button>
                {
                  Menu.multiLevel? (
                    <ul id={"dropdown-"+Menu.Title} className={"py-2 space-y-2 "  +(window.location.href.indexOf("/" + Menu.Title) !== -1?"" :"hidden")}>
                      {Menu.children.map((c, index) => (

                        <li className={open?"ml-3":""}>
                          <button type="button"
                            onClick={() => navigate("/" + Menu.Title + "/" + c.Link)}

                            className={`flex w-full  p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 ${(window.location.href.indexOf("/" + Menu.Title + "/" + c.Link) !== -1) && "bg-light-white text-orangeCrea"
                              }`}
                            aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                              {c.icon}
                           <span className={ `flex-1 ml-3 text-left whitespace-nowrap ${!open && "hidden"} origin-left duration-200`}  sidebar-toggle-item>   {c.Title}  </span>
                          </button>
                        </li>
                      ))
                      }
                    </ul>) : ""}

              </li>
            ))}
          </ul>
        </div>


        <div className="flex-1 p-7 overflow-y-auto">
          <h1 className="text-2xl font-semibold ">{window.location.href.split("/").pop().charAt(0).toUpperCase() + window.location.href.split("/").pop().slice(1).toLocaleLowerCase()}</h1>
          <div className="h-full pt-2">
            <Outlet />

          </div>
        </div>


      </div>





    </>

  )

}

export default Home







/* 


  {
              Menu.children?.length >0?(
              <ul className="pt-6">
              {Menu.children.map((c,index) => (

                <li className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4">{c.title}</li>
              ))
              }
              </ul>):"" }



  <button type="button" onClick={() => document.getElementById("dropdown-example").classList.contains('hidden')?document.getElementById("dropdown-example").classList.remove("hidden"):document.getElementById("dropdown-example").classList.add("hidden")} className="flex items-center p-2 w-full text-base font-normal text-emerald-700 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
            <i className="bi bi-clipboard2-check-fill"></i>
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Gestione lavoro</span>
                  <svg sidebar-toggle-item className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            <ul id="dropdown-example" className="hidden py-2 space-y-2">
            <li>
            <a href="#" onClick={(e)=>navigationClick(e,"panoramica")} className={selected==="panoramica"?subSelStyle:subNonSelStyle}>
            <i className="bi bi-calendar-week"></i>
               <span className="ml-3">Panoramica</span>
            </a>
         </li>
         <a href="#" onClick={(e)=>navigationClick(e,"Commessa")} className={selected==="ODL"?subSelStyle:subNonSelStyle}>
         <i className="bi bi-ui-checks"></i>
               <span className="ml-3">Commessa</span>
            </a>
            <li>
            <a href="#" onClick={(e)=>navigationClick(e,"MacchinariGL")} className={selected==="MacchinariGL"?subSelStyle:subNonSelStyle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-car-front" viewBox="0 0 16 16">
               <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
               <path fill-rule="evenodd" d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
            </svg>
               <span className="ml-3">Macchinari</span>
            </a>
         </li>


 <li
            onClick={()=> navigate("/"+Menu.title) }
              key={index}
              className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${
                (window.location.href.indexOf("/"+Menu.title) !== -1) && "bg-light-white"
              } `}
            >
              
              <li
            onClick={()=> navigate("/"+Menu.title) }
              key={index}
              className={`
              ${Menu.gap ? "mt-9" : "mt-2"} ${
                (window.location.href.indexOf("/"+Menu.title) !== -1) && "bg-light-white"
              } `}
            >
              <button type="button" className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 " aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
            <i className="bi bi-clipboard2-check-fill"></i>
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{Menu.title}</span>
                  <svg sidebar-toggle-item className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
              
            
             
            </li>



*/