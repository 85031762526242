import { DataGridPro, GridToolbar, itIT,  GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    useGridApiRef,
    gridVisibleRowCountSelector,
    gridVisibleColumnDefinitionsSelector,
    gridVisibleSortedRowEntriesSelector, } from '@mui/x-data-grid-pro';
  import React, { useEffect, useState,useMemo } from "react";
  import api from '../api/api';
  import moment from "moment";
  import { Search } from '@mui/icons-material';


  import Dropzone from "react-dropzone";
  import { useDropzone } from "react-dropzone";
import { ToastContainer,toast } from 'react-toastify';


  const ext = {
    doc: "application/msword",
    dot: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    docm: "application/vnd.ms-word.document.macroEnabled.12",
    dotm: "application/vnd.ms-word.template.macroEnabled.12",
    xls: "application/vnd.ms-excel",
    xlt: "application/vnd.ms-excel",
    xla: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
    xltm: "application/vnd.ms-excel.template.macroEnabled.12",
    xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
    xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    ppt: "application/vnd.ms-powerpoint",
    pot: "application/vnd.ms-powerpoint",
    pps: "application/vnd.ms-powerpoint",
    ppa: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
    ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
    ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    mdb: "application/vnd.ms-access",
    png: "application/png",
    pdf: "application/pdf",
  };


  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const Dashboard = () =>{
    const [notarized,setNotarized] = useState([])
    const [loading,setLoading] = useState(true);
    const [open,setOpen] = useState(false);
    
    const downloadFile = async (file,nomeOriginale) => {
      var re = /(?:\.([^.]+))?$/; 
      let estensione = "."+re.exec(nomeOriginale)[1];
      await api.post(
        "download",
        {
          file:file,
          nomeOriginale: nomeOriginale,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("JWT")}`,
          },
          responseType: "blob"
        },
        
      ) .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: ext[estensione.replace(".", "")] })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
     
          link.setAttribute(
            "download",nomeOriginale
          );
          document.body.appendChild(link);
          link.click();

      });
  /*
      await api
        .get("ftp", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
          params: {
            nomeOriginale: nomeOriginale,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: ext[estensione.replace(".", "")] })
          ); //prendo il file, lo rendo blob lo aggancio a un link e scarico
  
          const link = document.createElement("a");
          link.href = url;
       
            link.setAttribute(
              "download",nomeOriginale
            );
            document.body.appendChild(link);
            link.click();

        }); */
    };
  

    
      const columns = [
        {field:"logo",headerName:"Blockchain",renderCell:(params) => {return (<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 650"><title>ALGO_Logos_190320</title><g id="lINT7W"><polygon points="444.18 444.32 406.81 444.32 382.54 354.04 330.36 444.33 288.64 444.33 369.29 304.57 356.31 256.05 247.56 444.36 205.82 444.36 343.64 205.64 380.18 205.64 396.18 264.95 433.88 264.95 408.14 309.71 444.18 444.32"/></g></svg>)}
        },
        { field: 'HashId', headerName: 'Hash', flex:1 },
        {
          field: 'Tipologia',
          headerName: 'Tipologia',
          flex:1,
        },
        {
          field: 'Testo',
          headerName: 'Info',
          flex:1,
        },
        {
            field: 'Sha256',
            headerName: 'Sha256',
            flex:1,
          },
        {
          field: 'Data',
          headerName: 'Data',
          flex:1,
          renderCell:(params) => moment(params.row.Data).format("DD/MM/YYYY")
        }
        ,
        {
            field: '',
            headerName: '',
            flex:1,
            renderCell:(params) =>  
            <><button type="submit" onClick={() => handleVerification(params.row.HashId)} className="hover:bg-millerBlueOver text-white bg-millerBlue hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium  px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" >Verifica</button>
            {params.row.Tipologia==="File"?<button onClick={() => downloadFile(params.row.Sha256,params.row.Testo)} className="hover:bg-millerBlueOver text-white bg-millerBlue hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium  px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" >Download</button>:""}
            
            </>
          }
      ];
     
      
      const loadNotarized = async() => {// in  base al tasto schiacciato filtro
        
        await api.get('/notarized', {params:{email:"prova@prova.it"},headers: {'Authorization': `Bearer ${sessionStorage.getItem("JWT")}`
            }})
            .then( res=> {
                setNotarized(res.data.data);
    
            })
        
    }
    
    const handleVerification = (hash) => {
        window.open('https://testnet.algoexplorer.io/tx/'+hash, '_blank'); //attenzione metti la mainnet
    
        //Add the link somewhere, an appendChild statement will do.
        //Then run this
        document.getElementById('someLink').click();





    }
    
 

    
    useEffect(() => {
    
        loadNotarized();
        setLoading(false)
    },[])
    
    
    
    
    
    
    
    
    
        return (       
            <>
              <ModalNewUpload loadNotarized={loadNotarized} />
         <div className='flex flex-col space-y-2'>
            
    <div className=" overflow-hidden  w-full">
      <div className="flex flex-row-reverse w-full space-x-4">
        <div className="relative max-w-sm">
      
      </div>
      <div className="relative max-w-sm">
      <div className="relative">
  
    <button type="submit" onClick={() => document.getElementById("openModal").click()} className="hover:bg-millerBlueOver text-white bg-millerBlue hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium  px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" >Nuovo</button>
    </div>
  </div>
    
        
        
        
      </div>
      
     
    
    
    </div>
    

    
    
    <div className=" overflow-hidden shadow-lg bg-white w-full  p-4">
  
    <div className="flex flex-col w-full space-x-4  h-[600px]">
        <div className="h-full">
          <DataGridPro  loading={loading} 
          columns={columns} rows={notarized} 
          getRowId={(el) => el.HashId} 
           localeText={itIT.components.MuiDataGrid.defaultProps.localeText} 
           components={{Toolbar: GridToolbar}}
        
          
          />
        </div>
      </div>
      </div>
    
    
    
  
    
      </div>
      </>
        )
    }
    
    export default Dashboard
    
    
    



    const ModalNewUpload = (props) => {
const [open,setOpen] = useState(false);
const [tipologia,setTipologia] = useState("Text");
const [testo,setTesto] = useState("");
const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();
const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );


  const [files, setFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };



const notarizeBlockchain  = async() =>{

if(tipologia ==="Text"){
    let not = await api //notarize to blockchain
    .post(
      "notarize/text",
      {  email: "prova@prova.it",text:testo },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("JWT")}`,
        },
      }
    )
    }

    if(tipologia ==="File"){
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("email", "prova@prova.it");
        let not = await api //notarize to blockchain
        .post(
            "notarize/file",formData,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("JWT")}`,
            },
          }
        )
        }

        toast.success('Notarizzazione avvenuta con successo', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
 props.loadNotarized();
 setOpen(false);


}


const handleUpload = async () => {
    if (files.length === 0) {
    
    } else {
      const formData = new FormData();

    
        formData.append("file", files[0]);
   
      try {
        await api.post("caricaDoc", formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
   
      } catch {
     
   
      }
    }
  };



return(<>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />


<button id="openModal" stle={{display:"none"}} onClick={()=>{setOpen(true);setFiles([]);setTipologia("Text");setTesto("");}}></button>
    {
open?
    <>
  <div>
            <div className="py-12 bg-black bg-opacity-25  transition absolute duration-150 ease-in-out z-10  top-0 right-0 bottom-0 left-0" id="modal">
                <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400 ">
                       
                        <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Nuova notarizzazione</h1>
                        <label htmlFor="tipologia" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                            Tipologia 
                        </label>
                        <select id="name" value={tipologia} onChange={(e) => setTipologia(e.target.value)} className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="James" >
                        <option value={"Text"} >Testo</option>
                        <option value={"File"} >File</option>
                        </select>
                        <label htmlFor="tipologia" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                            Blockchain 
                        </label>
                        <select id="name" value={"Algorand"}  className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="James" >
                        <option value={"Algorand"}>
                       
                          Algorand
                          
                          </option>
                       
                        </select>
                        {tipologia==="Text"?
                        <>
                        <label htmlFor="expiry" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                         Testo
                        </label>
                        <div className="relative mb-5 mt-2">
                            <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                               
                            </div>
                            <input id="expiry" value={testo} onChange={(e)=>setTesto(e.target.value)} className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="" />
                        </div></>:
<>
<Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia qui o fai clic per selezionare i file</p>
              </div>
            )}
          </Dropzone>
          <aside>
            <h4>File</h4>
            {files?<ul>{files[0]?.path}</ul> :""}
          </aside>
</>

    }
                        
                        
                        <div className="flex items-center justify-start w-full">
                           
                            <button className="focus:outline-none transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm" onClick={notarizeBlockchain}>Notarizza</button>
                            <button className="focus:outline-none ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" onClick={() => 
                                setOpen(false)}>
                                Cancel
                            </button>
                        </div>
                        <div className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out" onClick={() => 
                                setOpen(false)} >
                            <svg xmlns="http://www.w3.org/2000/svg"  aria-label="Close" className="icon icon-tabler icon-tabler-x" width={20} height={20} viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        
        </div></> :""}
</>
)
    }