import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import PrivateRoutes from './utils/PrivateRoutes';
import Analytics from './components/Analytics';
import Dashboard from './components/Dashboard';
import "./App.css"
import { LicenseInfo } from "@mui/x-data-grid-pro";

import "react-toastify/dist/ReactToastify.css";
LicenseInfo.setLicenseKey("bbfa42661755c336eeead7dc49a7dd92Tz01ODM1NCxFPTE3MDYxODI3MDY0MzIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=")



const App = ()=> {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route element={<PrivateRoutes />} >
        <Route path="/" element={<Home />} >
            <Route path="/Analytics/Vendite" element={<Analytics />} />
            <Route path="/Home" element={<Dashboard />} />
          </Route>

      </Route>
       <Route path="/login" element={<Login />} />
    </Routes>
    </BrowserRouter>
 </>
  );
}

export default App;
